export enum GameTypeEnum {
  LiveCasino = "LC",
  Slot = "SL",
  Sport = "SB",
  Lottery = "LT",
  Fishing = "FH",
  BoardGame = "BG",
  CookFighting = "CF",
  ESport = "ES",
  Poker = "PK",
}

export enum SubGameEnum {
  Lobby = 1,
  HotGame = 0,
}
